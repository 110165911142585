import { getCookie, setCookie } from './cookie'

const envPrefix = {
  staging: 'staging.',
  prod: '',
}

function sendYardzenLead(data) {
  const env = window.location.hostname.includes('yardzendev')
    ? 'staging'
    : 'prod'

  const url = `https://${envPrefix[env]}s.yardzen.com/attentive`
  let fbc = getCookie('_fbc')
  let fbp = getCookie('_fbp')

  fetchAnonId()
    .then((yzsa) => {
      const body = Object.assign(data, { click_id: fbc, browser_id: fbp, yzsa })

      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
    })
    .then((response) => {
      if (response.ok) {
        console.log('Lead sent successfully')
      } else {
        console.error('Lead failed to send')
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    })

  const promoCodeUrl = `https://${
    window.location.hostname.includes('yardzendev') ? 'staging.' : ''
  }pangaea.yardzen.com/v1/promotional-codes/single-use`

  fetch(promoCodeUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: data.email,
      startTime: new Date(),
      attentiveData: {
        email: data.email,
        phone: data.phone,
      },
    }),
  }).then(async (response) => {
    if (response.ok) {
      const codeResponse = await response.json()
      const codeData = {
        code: codeResponse.code,
        email: codeResponse.email,
        endTime: codeResponse.endTime,
        status: codeResponse.status,
      }

      setCookie('yzhpb', 'false', 365)
      setCookie('yzpcode', JSON.stringify(codeData), 7)
      window.location.reload(true)
    }
  })
}

async function fetchAnonId() {
  let yzsa = getCookie('yzsa')

  if (yzsa && yzsa != '') return yzsa

  const env = window.location.hostname.includes('yardzendev')
    ? 'staging'
    : 'prod'
  const url = `https://${envPrefix[env]}s.yardzen.com/rand`

  yzsa = await fetch(url).then((r) => r.text())
  setCookie(
    'yzsa',
    yzsa,
    365,
    /yardzen\.com$/.test(window.location.origin) ? 'yardzen.com' : ''
  )

  return yzsa
}

export default function initYardzenLeadCallback() {
  window.sendYardzenLead = sendYardzenLead
}

// Example usage
// const requestData = {
//   email: "example@attn.com",
//   event: "EMAIL_SEND", // EMAIL_SEND or JOIN
//   phone: "5555555555",
// };
//
// sendYardzenLead(requestData);
