import { getCookie, setCookie } from './cookie'
import $ from 'jquery'

export default function initFirstTimeUser() {
  window.addEventListener('load', returningVisitorActions)
}

function isFirstTimeVisitor() {
  const firstTimeVisitDate = new Date(getCookie('yzftv'))
  const today = new Date()
  return (
    firstTimeVisitDate &&
    firstTimeVisitDate.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)
  )
}

function returningVisitorActions() {
  if (isFirstTimeVisitor()) {
    return
  }
  const domain = window.location.href
  const packages = domain + 'packages'
  const newDestination = domain + 'design-packages'

  $(`a[href="${packages}"]`).attr('href', newDestination)
  $(`a[href="/packages"]`).attr('href', newDestination)
}
