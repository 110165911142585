function loadCalendlyScript(callback) {
  const script = document.createElement('script');
  script.src = 'https://assets.calendly.com/assets/external/widget.js';
  loadCalendlyCSS();
  script.onload = callback;
  document.head.appendChild(script);
}

function loadCalendlyCSS() {
  const css = document.createElement('link');
  css.rel = 'stylesheet';
  css.href = 'https://assets.calendly.com/assets/external/widget.css';
  document.head.appendChild(css);
}

export default function initCalendlyButton() {
  loadCalendlyScript(() => {
    Calendly.initBadgeWidget({
      url: 'https://calendly.com/d/cp92-3g6-89j/yardzen-consult-call-let-s-discuss-your-project',
      text: 'Schedule A Free Call',
      color: '#4e5a44',
      textColor: '#ffffff',
      branding: false
    });
  });
}
